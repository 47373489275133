.validation-error {
    font-weight: bold;
    color: red;
}

.validation-error::after {
    content: "*";
    color: red;
    font-weight: bold;
}

.not-found-error {
    color: red;
    font-size: 14px;
}

.no-marginbot {
    margin-bottom: 0;
}