a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 16px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
  color: #321063;
  color: #321063;
  font-family: navigo, sans-serif;
  font-weight: bold;
}

.navbar-nav a {
  color: #321063;
}

.navbar-light .navbar-nav .nav-link {
  color: #321063;
  font-family: navigo, sans-serif;
  font-weight: bold;
}

.navbar > .container {
  justify-content: space-between;
}

.navbar-toggler {
  margin-right: 10px;
}

.login-fixed {
  position: relative;
  top: 0;
  left: auto;
  padding-left: 20px;
  z-index: 1030;
}
