footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 0 0;
    background: #DEDEDF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

    footer span,
    footer a, footer a:visited {
        margin: 0 10px 0 0;

        text-align: left;
        line-height: 24px;
        letter-spacing: 0;
        font: normal normal medium 12px/24px Navigo;
        font-size: 12px;
        font-weight: bold;
        color: #002135;
        text-decoration: none;
    }

    footer p {
        font-size: 12px;
        color: #767676;
    }


footer a:hover {
    color: #002135;
    text-decoration: underline;
}