body {
  font-family: navigo, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
    margin-bottom: calc(8.25em + 40px);
}

.App-link {
  color: #61dafb;
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  font-family: navigo, sans-serif;
  background-color: #010352;
  border-color: #ff915f;
  color: #ffff;
}

.btn-primary:hover {
  background-color: #ff915f;
  color: #010352;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  font-family: navigo, sans-serif;
  background-color: #1b1c1d;
  border-color: #1b1c1d;
}

.btn-primary:disabled {
  font-family: navigo, sans-serif;
  background-color: #e0e1e2;
  border-color: #e0e1e2;
}

h1 {
  font-size: 30px;
  margin-bottom: 0px;
  font-weight: bold;
}

h2 {
  font-size: 26px;
  margin-bottom: 0px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  margin-bottom: 0px;
}

h4 {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: bold;
}

h5 {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.breadcrumb-circle {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #767676;
  font-family: navigo, sans-serif;
  font-style: italic;
  font-size: 35px;
  color: #767676;
  text-align: center;
  display: inline-block;
}

.active-breadcrumb-circle {
  border: 2px solid #ff915f;
}

.breadcrumb-border-right {
  border-right: 1px solid #dededf;
}

.header-breadcrumb {
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
  padding: 3px;
}

.breadcrumb-text {
  margin-left: 3px;
  font-family: navigo, sans-serif;
  font-size: 18px;
  align-items: center;
  display: flex;
  height: 50px;
}

.active {
  color: #ff915f;
}

.validation-circle {
  position: absolute;
  top: -3px;
  left: 0px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  text-align: center;
  vertical-align: text-top;
  line-height: normal;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #ff6a5f;
  padding-top: 2px;
}

/* || Form Elements */

label {
  color: #333333de;
  line-height: 16px;
  font-size: 13px;
  font-weight: bold;
}

input {
  line-height: 38px;
  height: 38px;
}

input::-webkit-input-placeholder, /* WebKit, Blink, Edge */
input:-moz-placeholder, /* Mozilla Firefox 4 to 18 */
input::-moz-placeholder, /* Mozilla Firefox 19+ */
input:-ms-input-placeholder /* Internet Explorer 10-11 */ {
  color: #b5b5b5;
}

input[type="number"],
input[type="text"],
input[type="datetime"],
textarea {
  color: #000000;
  border: 1px solid #dededf;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
}

option {
  position: relative;
  color: #000000;
  padding: 5px 10px 5px 10px;
  left: -10px;
  width: calc(100% + 20px);
}

option:checked,
option:hover,
option:active,
option:focus {
  background: #f8f8f8;
  font-weight: bold;
  box-shadow: none;
  outline: none;
}

select::-webkit-scrollbar {
  background-color: #e6e6e6;
  width: 10px;
}

select::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 15px;
  width: 10px;
}

.form-check {
  display: flex;
  align-items: flex-end;
}

.form-check-label {
  line-height: 20px;
  margin: 0 0 0 4px;
  font-size: 11px;
}

input[type="radio"] {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
}

input[type="radio"]::before {
  content: "";
  display: block;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #321063;
  opacity: 1;
}

input[type="radio"]:checked::after {
  content: "";
  display: block;
  background-color: #321063;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 4px;
  left: 4px;
}

input[type="checkbox"] {
  border-color: #dededf;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #321063;
  border-color: #321063;
}
