.volunteer-home-header {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.case-subheader {
  text-align: center;
  font-weight: bold;
}

.case-subheader p {
  font-size: 19px;
}

.case-item {
  border-bottom: 3px solid #dededf;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.case-item h3 {
  width: 80%;
}

.case-item p{
  width: 100%;
}

.case-item-buttons {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.case-item-buttons button {
  margin-right: 5px;
}
